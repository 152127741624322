import React from "react";
import PropTypes from "prop-types";
import MessageBox from "./MessageBox";

function Messages(props) {
  const { className, data } = props;

  return (
    <div className={className}>
      {data.map((element, index) => {
        return <MessageBox element={element} key={element.posted_at + index} />;
      })}
    </div>
  );
}

Messages.propTypes = {
  className: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      sentiment_score: PropTypes.number.isRequired,
      posted_at: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired
    }).isRequired
  ).isRequired
};

export default Messages;
