export const colors = {
  positive: "#3aef9d",
  negative: "#ff5c84",
  darkGrey: "#1a1a1a",
  grey: "#333333",
  lightGrey: "#F2F2F2"
};

export const scaleColors = [
  "#FF5C84",
  "#FC82A0",
  "#F9A7BB",
  "#F5CCD6",
  "#F2F2F2",
  "#C4F1DD",
  "#96F1C8",
  "#68F0B2",
  "#3AEF9D"
];
