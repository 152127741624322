import React from "react";
import { node, number, shape } from "prop-types";

/**
 * Render a transformed margin group as per D3.js margin conventions.
 * @param {object} props - React prop
 * @param {object} props.margin - D3.js conventional margin object
 * @param {array|element} props.children - React children
 */
const MarginGroup = React.memo(props => {
  const { margin, children } = props;

  return (
    <g transform={`translate(${margin.left}, ${margin.top})`}>{children}</g>
  );
});

MarginGroup.displayName = "MarginGroup";

MarginGroup.propTypes = {
  margin: shape({
    top: number.isRequired,
    right: number,
    bottom: number,
    left: number.isRequired
  }).isRequired,
  children: node.isRequired
};

export default MarginGroup;
