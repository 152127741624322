import React from "react";
import PropTypes from "prop-types";

import InactiveLinkIcon from "./InactiveLinkIcon";
import ActiveLinkIcon from "./ActiveLinkIcon";

function LinkIcon(props) {
  const { icon, sidebarState, elementIdentifier } = props;

  if (sidebarState.contentIdentifier === elementIdentifier)
    return <ActiveLinkIcon icon={icon} />;
  else return <InactiveLinkIcon icon={icon} />;
}

LinkIcon.propTypes = {
  elementIdentifier: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  sidebarState: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    contentIdentifier: PropTypes.string,
    windowScrollY: PropTypes.number.isRequired
  }).isRequired
};

export default LinkIcon;
