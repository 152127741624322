import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components/macro";
import InactiveLinkIcon from "./InactiveLinkIcon";

const LinkIcon = styled(InactiveLinkIcon)`
  background-color: white;
  color: black;
`;

function ActiveLinkIcon(props) {
  const { className, icon } = props;
  return <LinkIcon className={className} icon={icon} />;
}

ActiveLinkIcon.propTypes = {
  icon: PropTypes.string.isRequired
};

export default ActiveLinkIcon;
