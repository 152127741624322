import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { scaleTime } from "d3-scale";
import { extent, min } from "d3-array";
import { calcSlots, filterBySentimentScore } from "../helpers/helpers";
import ChartElement from "./ChartElement";
import MarginGroup from "./MarginGroup";
import Tick from "./Tick";
import TickText from "./TickText";

function GraphMessages(props) {
  const {
    data,
    margin,
    scaleColors,
    height,
    endOfLastSession,
    isAimlAwake
  } = props;
  const width = props.width - margin.left - margin.right;

  const heightTickText = 15;
  const heightGraph = height - heightTickText;

  // Extent period starts with the first fetched post
  // minus an offset of 5 minutes so the first post does not stick in the left corner of the graph.
  const extendStart = useMemo(
    () => min(data, d => new Date(d.posted_at)) - 5 * 60 * 1000,
    [data]
  );

  // Extent period ends with the end of the last session
  // plus an offset of 5 minutes so the last post does not stick in the right corner of the graph.
  // If AIML is awake the extent period ends with the current time
  // so the user can easy recognize how much time has passed since the last message has been posted.
  const extendEnd = isAimlAwake ? Date.now() : endOfLastSession + 5 * 60 * 1000;

  const hoursBetween = (extendEnd - extendStart) / (1000 * 60 * 60);

  // Make sure that the graph displays at least a period of 2 hours
  // so at least 2 ticks are drawn and the user is able to recognize the scaling of the axis
  const extentPeriodPostedAt =
    hoursBetween < 2
      ? [extendEnd - 2 * 60 * 60 * 1000, extendEnd]
      : [extendStart, extendEnd];

  const xScale = scaleTime()
    .domain(extent(extentPeriodPostedAt))
    .range([0, width]);

  // Draw one tick for each full hour
  const numberOfTicks = Math.floor(
    (extentPeriodPostedAt[1] - extentPeriodPostedAt[0]) / (1000 * 60 * 60)
  );

  const ticks = xScale.ticks(numberOfTicks);
  const tickLabelWidth = 100;

  let numberOfDisplayableLabels = Math.floor(width / tickLabelWidth);
  // At least two labels must be displayed (even if it does not look good)
  // so the user is able to recognize the scaling of the axis
  if (numberOfDisplayableLabels <= 2) numberOfDisplayableLabels = 2;

  // Display every nth tick label to make sure they do not overlap
  const displayEveryNthTickLabel =
    ticks.length <= numberOfDisplayableLabels
      ? 1
      : Math.floor(numberOfTicks / numberOfDisplayableLabels + 1);

  const elementInfo = {
    size: 5,
    stroke: 1,
    padding: 4
  };

  const filteredData = filterBySentimentScore(data);

  const slots = {
    positiveSentimentScore: calcSlots(
      filteredData.positiveSentimentScore,
      xScale,
      elementInfo,
      heightGraph / 2
    ),
    negativeSentimentScore: calcSlots(
      filteredData.negativeSentimentScore,
      xScale,
      elementInfo,
      heightGraph / 2
    )
  };

  return (
    <svg
      stroke="white"
      fill="white"
      height={height + margin.top + margin.bottom}
      width={width + margin.left + margin.right}
    >
      <MarginGroup margin={margin}>
        <g
          className="chart_elements"
          transform={`translate(${0} ${heightGraph / 2})`}
        >
          {/* draw elements with neutral sentiment score  */}
          <g className="neutral_sentiment">
            {filteredData.neutralSentimentScore.map((element, index) => {
              const position = {
                x: xScale(new Date(element.posted_at)),
                y: 0
              };
              return (
                <ChartElement
                  key={index + "_" + element.posted_at}
                  position={position}
                  element={element}
                  elementInfo={elementInfo}
                  color={scaleColors[Math.round(scaleColors / 2)]}
                />
              );
            })}
          </g>
          {/* draw elements with positive sentiment score  */}
          <g className="positive_sentiment">
            {slots.positiveSentimentScore.map((currentRow, index) => {
              return currentRow.map((element, elementIndex) => {
                const position = {
                  x: xScale(new Date(element.posted_at)),
                  y: -((elementInfo.size + elementInfo.padding) * (index + 1))
                };
                return (
                  <ChartElement
                    key={elementIndex + "-" + element.posted_at}
                    position={position}
                    element={element}
                    elementInfo={elementInfo}
                    color={scaleColors[scaleColors.length - 1]}
                  />
                );
              });
            })}
          </g>
          {/* draw elements with negative sentiment score  */}
          <g className="negative_sentiment">
            {slots.negativeSentimentScore.map((currentRow, index) => {
              return currentRow.map((element, elementIndex) => {
                const position = {
                  x: xScale(new Date(element.posted_at)),
                  y: (elementInfo.size + elementInfo.padding) * (index + 1)
                };
                return (
                  <ChartElement
                    key={elementIndex + "/" + element.posted_at}
                    position={position}
                    element={element}
                    elementInfo={elementInfo}
                    color={scaleColors[0]}
                  />
                );
              });
            })}
          </g>
        </g>
        <g>
          {ticks.map((tick, index) => {
            return (
              <React.Fragment key={tick}>
                {index % displayEveryNthTickLabel === 0 ? (
                  <>
                    <Tick tick={tick} xScale={xScale} height={heightGraph} />
                    <TickText tick={tick} xScale={xScale} height={height} />
                  </>
                ) : (
                  <Tick
                    tick={tick}
                    xScale={xScale}
                    height={height}
                    small={true}
                  />
                )}
              </React.Fragment>
            );
          })}
          <line
            className="axis"
            stroke="white"
            x1="0"
            x2={width}
            y1={heightGraph / 2}
            y2={heightGraph / 2}
          />
        </g>
      </MarginGroup>
    </svg>
  );
}

GraphMessages.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      posted_at: PropTypes.number.isRequired,
      sentiment_score: PropTypes.number.isRequired
    }).isRequired
  ).isRequired,
  height: PropTypes.number.isRequired,
  margin: PropTypes.shape({
    top: PropTypes.number.isRequired,
    bottom: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    right: PropTypes.number.isRequired
  }).isRequired,
  scaleColors: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  width: PropTypes.number.isRequired
};

export default GraphMessages;
