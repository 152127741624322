import React from 'react';
import PropTypes from 'prop-types';

import img from '../img/AimlHand.jpg';
import styled from 'styled-components/macro';

const AimlHand = styled.img`
  width: 100%;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Link = styled.a`
  color: white;
`;

function Instruction(props) {
  const { className } = props;
  return (
    <div className={className}>
      <section>
        <h2>Who can tame AIML?</h2>
        <p>
          Now that we are collecting more and more data, we know more about a
          specific neighborhood. We can map the average income, the number of
          soot particles in the air, the amount of single people that live there
          and even the state of mind of the inhabitants.{' '}
          <Bold>But, do we really want to know all that?</Bold> Datacreature
          AIML wants to initiate a conversation about this. The digital
          'creature' AIML reflects the sentiment of the neighborhood; if the
          neighborhood is happy, then AIML is great company. If the neighborhood
          is not doing well, AIML will transform in a fierce beast that is
          difficult to tame.
        </p>
        <p>
          AIML is developed by the internationally renowned data visualization
          studio CLEVER°FRANKE in collaboration with data engineer Bruno Jakic
          from design agency AI-applied: “With camera systems, sensors and
          social media data, we can increasingly map behaviors and emotions in a
          neighborhood. With the use of algorithms that learn from data, all
          kinds of smart services can be developed. The way in which data
          influences our daily activities is therefore undeniable.” says Gert
          Franke. With the help of data we can navigate conveniently from A to
          B, we consult whether it will be pouring rain and we know where our
          friends are. We tend to see data as tools, but in the meantime we have
          become entangled with data. That means we have to start thinking about
          our relationship with it.{' '}
          <Bold>What do we actually want with data?</Bold> AIML wants to provoke
          answers to that question.
        </p>
        <p>
          The smart datacreature absorbs as much information as possible about
          the neighborhood. Developer Bruno Jakic: “By geotagging tweets and
          subsequently determining their polarity, we get an impression of the
          neighborhood.” In normal human language, this means that Jakic filters
          location-specific information from social media. This information is
          supplemented with open data from the municipality and data from
          companies, and is then analyzed. If many positive words are used, we
          can conclude that there is an optimistic mood in the neighborhood. In
          short, the combined datamenu determines AIML’s state of mind. If the
          neighborhood feels good, AIML will feel happy. The datacreature will
          show this by making a beautiful pen drawing. However, if there is
          tension in the neighborhood, AIML’s hairs will stand upright or it
          will crawl into a corner in its cage.
        </p>
        <AimlHand src={img} alt="robot arm" />
        <p>
          Gert: “We consciously opted for the confrontation with an animal. Pets
          also respond strongly to human emotions. People also choose animals
          that look like them. That symbiotic relationship between humans and
          animals fits well with whatever we want to put on the agenda with
          AIML. Data is part of us.” Bruno adds: “AIML allows the fusion of data
          to be physically experienced and makes, what is now only visible for a
          few data freaks like us, transparent for everyone. This is important,
          because we have to have a social debate about what we want with data.
          We are therefore curious to know how people will interact with AIML
          and who is able to tame the datacreature.”
        </p>
      </section>
      <section>
        <h2>Privacy Policy</h2>
        <h3>Why do we collect personal information?</h3>
        <p>
          Personal information will be used in order for Aiml to operate during
          the exhibition at the Dutch Design Week in Eindhoven (1). Aiml
          analyses the local sentiment of Eindhoven based on nearby posts on
          Twitter (2). After the exhibition a further study will be conducted to
          analyse the correlation between the local sentiment during the
          exhibition and the facial expressions of the subjects (3).
        </p>
        <h3>What is being processed and stored?</h3>
        <p>
          In order for Aiml to operate, it uses a camera-module and
          force-sensitive-resistor, and software-based camera vision to study
          the expression on the face of the subject. The information, containing
          the presence of the individual (1), a generated outline of the face of
          the subject (2) and a generated dataset entailing an analysis on the
          facial expression of the subject (3), will be processed and stored
          locally. The datasets of each individual will and anonymized for the
          safety of the subject. The generated outline of the face of each
          subject is designed to be a non-recognizable Scalable Vector Graphics
          (SVG) and therefore does not show sensitive information regarding the
          face of the subject. After processing a dataset containing the outline
          of the face and the corresponding generated sentiment score will be
          sent to the cloud computing platform{' '}
          <Link href="https://azure.microsoft.com/en-us/">Azure</Link>. This
          information will only be stored for the duration of the exhibition in
          order for the web-interface (Aiml Monitor) to operate. After the
          exhibition the information will be removed from Azure and stored
          locally. Independently of the presence and the sentiment score of the
          subject, Aiml uses an API developed by{' '}
          <Link href="https://ai-applied.nl/">AI Applied</Link> to access,
          process and utilize social media data from Twitter. The information
          gathered from Twitter is restricted to the content, time of placement
          and whether the content of the tweet has a positive or negative
          sentiment score by nearby public Twitter accounts.
        </p>
        <h3>How long is the data stored?</h3>
        <p>
          This information will be reserved for a maximum time-period of two
          months and is prohibited from being shared with third-parties or
          leaving the (digital) premises of CLEVERºFRANKE, with the exception of
          being withdrawn by the subject only (see next paragraph). In the
          period between capture and removal, the information might be used for
          further processing and analyses. This is to further study the
          correlation between the local sentiment during the exhibition and the
          facial expressions of the subjects.
        </p>
        <h3>Who has access to the data?</h3>
        <p>
          The information is stored locally for a maximum time-period of two
          months where only CLEVERºFRANKE has access to this data. During the
          exhibition the information will be stored both locally and in the
          cloud computing platform{' '}
          <Link href="https://azure.microsoft.com/en-us/">Azure</Link>. During
          the exhibition CLEVERºFRANKE will publish a web-interface
          (Aiml-monitor) where the tweets, sentiment-scores, recent outlines of
          faces and a live-view of the camera installed on Aiml will be shown.
          After the exhibition the Aiml-monitor will be taken offline and the
          data will be stored locally. If you have questions regarding your own
          data, or wish to withdraw your data, contact our designated head of
          GDPR Gert Franke on{' '}
          <Link href="mailto:gert@cleverfranke.com">gert@cleverfranke.com</Link>
          . You can only access your own data and make sure to state the time
          you interacted with the installation of Aiml.
        </p>
      </section>
    </div>
  );
}

Instruction.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Instruction;
