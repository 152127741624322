import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components/macro";

const LinkIcon = styled.div`
  margin: 0.3rem;
  font-size: 1.3em;
  border: 1px solid white;
  min-width: 1.3em;
  min-height: 1.3em;
  width: 1.3em;
  height: 1.3em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 750px) {
    margin-right: 0.75rem;
  }
`;

function InactiveLinkIcon(props) {
  const { className, icon } = props;

  return <LinkIcon className={className}>{icon}</LinkIcon>;
}

InactiveLinkIcon.propTypes = {
  icon: PropTypes.string.isRequired
};

export default InactiveLinkIcon;
