import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components/macro";
import { colors } from "../styles/colors";
import AimlViewButton from "./AimlViewButton";

const Wrapper = styled.div`
  background-color: ${colors.darkGrey};
  display: flex;
  flex-direction: column;
`;

const AimlVideo = styled.iframe`
  background-color: black;
  border: 0;
  height: 100%;
  flex-grow: 1;
  min-height: 300px;
`;

function AimlView(props) {
  const { className, renderAimlVideo, setRenderAimlVideo, youtubeLink } = props;

  return (
    <Wrapper className={className}>
      <AimlViewButton
        renderAimlVideo={renderAimlVideo}
        setRenderAimlVideo={setRenderAimlVideo}
      />
      {renderAimlVideo && (
        <AimlVideo
          src={"https://www.youtube.com/embed/" + youtubeLink + "?&autoplay=1"}
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      )}
    </Wrapper>
  );
}

AimlView.propTypes = {
  className: PropTypes.string.isRequired,
  renderAimlVideo: PropTypes.bool.isRequired,
  setRenderAimlVideo: PropTypes.func.isRequired
};

export default AimlView;
