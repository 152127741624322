import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components/macro";
import { scaleQuantize } from "d3-scale";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  @media screen and (min-width: 830px) {
    flex-direction: row;
    justify-content: space-around;
  }
  @media screen and (min-width: 1500px) and (min-height: 900px) {
    margin: 0;
  }
`;

const Set = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 1rem;
  justify-content: center;
  align-items: center;
  color: white;
`;

const Paragraph = styled.p`
  margin-left: 1rem;
  max-width: 15rem;
`;

const Digit = styled.p`
  color: white;
  font-weight: bold;
  line-height: 1;
  font-size: 1.75rem;
  margin: 0.5rem 0;
  @media screen and (min-width: 500px) {
    font-size: 3rem;
  }
`;

const Value = styled.span`
  color: ${({ color }) => color};
`;

const Span = styled.span`
  color: rgba(242, 242, 242, 0.3);
`;

function KPIs(props) {
  const { data, scaleColors, hoursBack } = props;
  const { sentiment_trend: sentimentTrend, message_count: messageCount } = data;

  let sentimentTrendFormatted;
  let isPositive = true;

  if (sentimentTrend || sentimentTrend === 0) {
    sentimentTrendFormatted = Math.round(Math.abs(sentimentTrend * 100)) + "%";
    isPositive = sentimentTrend >= 0;
  }

  const color = scaleQuantize()
    .domain([-0.5, 0.5])
    .range(scaleColors);

  return (
    <Wrapper>
      <Set>
        <Digit>
          <Span>{isPositive ? "+" : "-"}</Span>
          <Value color={color(sentimentTrend)}>{sentimentTrendFormatted}</Value>
        </Digit>
        <Paragraph>
          My average sentiment over the last {hoursBack} hours
        </Paragraph>
      </Set>
      <Set>
        <Digit>{messageCount}</Digit>
        <Paragraph>
          The volume of messages i’ve received in the last {hoursBack} hours
        </Paragraph>
      </Set>
    </Wrapper>
  );
}

KPIs.propTypes = {
  data: PropTypes.shape({
    sentiment_trend: PropTypes.number,
    message_count: PropTypes.number,
    hours_back: PropTypes.number
  }).isRequired,
  scaleColors: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
};

export default KPIs;
