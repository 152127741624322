import React from "react";
import PropTypes from "prop-types";

import styled, { css } from "styled-components/macro";
import SidebarContent from "./SidebarContent";
import { closeSidebar } from "../helpers/helpers";

const StyledSidebar = styled.div`
  transform: translateX(100%);
  right: 0;
  background-color: black;
  transition: transform 0.3s linear;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateX(0);
    `};

  position: fixed;
  width: 90%;
  top: calc(70px + 1rem);
  bottom: 0;
  color: white;
  display: flex;

  @media screen and (min-width: 900px) {
    width: 70%;
  }

  @media screen and (min-width: 1200px) {
    width: 60%;
  }

  @media screen and (min-width: 1500px) and (min-height: 900px) {
    top: 0;
    height: 100%;
    position: relative;
    transform: translateX(0);
    transition: width 0.3s linear;
    width: 0;
    ${({ isOpen }) =>
      isOpen &&
      css`
        width: calc(33vw - 2rem);
      `};
    margin-left: 0;
  }
`;

const Wrapper = styled.div`
  opacity: 0;
  transition: opacity 0.2s linear;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transition: opacity 0.2s linear 0.5s;
      opacity: 1;
    `};
`;

const CloseSidebar = styled.a`
  position: fixed;
  padding: 1.25rem;
  right: 1rem;
`;

const StyledPath = styled.path`
  stroke: white;
  fill: none;
  stroke-width: 1;
`;

const StyledSidebarContent = styled(SidebarContent)`
  p,
  ul {
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.5em;
  }
  width: calc(100% - 6rem);
  height: calc(100vh - 70px - 5rem);
  overflow-y: scroll;
  right: 0;
  padding: 1rem 4rem 3rem 2rem;
`;

function Sidebar(props) {
  let { setSidebarState, sidebarState } = props;

  const handleCloseSidebar = event => {
    event.preventDefault();
    closeSidebar(sidebarState, setSidebarState);
  };

  return (
    <StyledSidebar isOpen={sidebarState.isOpen}>
      <Wrapper isOpen={sidebarState.isOpen}>
        {sidebarState.isOpen && (
          <>
            <CloseSidebar href="#" onClick={handleCloseSidebar}>
              <svg height="20" width="20">
                <StyledPath d="M0 0 L20 20" />
                <StyledPath d="M20 0 L0 20" />
              </svg>
            </CloseSidebar>
            <StyledSidebarContent sidebarState={sidebarState} />
          </>
        )}
      </Wrapper>
    </StyledSidebar>
  );
}

Sidebar.propTypes = {
  setSidebarState: PropTypes.func.isRequired,
  sidebarState: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    contentIdentifier: PropTypes.string,
    windowScrollY: PropTypes.number.isRequired
  }).isRequired
};

export default Sidebar;
