import React from "react";
import styled from "styled-components/macro";

const StyledSplashScreen = styled.div`
  color: white;
  padding: 1em;
  z-index: 100;
  background-color: black;
  height: calc(100vh - 2em);
  width: calc(100vw - 2em);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    margin: 0.5em;
    text-align: center;
  }
`;

function SplashScreen(props) {
  return <StyledSplashScreen>{props.children}</StyledSplashScreen>;
}

export default SplashScreen;
