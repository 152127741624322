import React from 'react';
import PropTypes from 'prop-types';

import img from '../img/AimlSetUp.jpg';
import styled from 'styled-components/macro';

const AimlSetUp = styled.img`
  width: 100%;
`;

const Link = styled.a`
  color: white;
`;

const Bold = styled.span`
  font-weight: bold;
`;

function Information(props) {
  const { className } = props;
  return (
    <div className={className}>
      <h2>About Aiml</h2>
      <p>
        Aiml is part of the interactive circus show 'Cirque du Data', a project
        of data design agency CLEVER°FRANKE and city lab RAUM in Utrecht. Aiml
        makes drawings influenced by the sentiment of her environment.{' '}
        <Bold>
          Aiml shows how a machine gains insight into people's mood and feelings
          by using artificial intelligence (AI) and machine learning.
        </Bold>{' '}
        With this project we investigate how these techniques can be integrated
        in public space, and thus make a positive contribution to the future of
        the city.
      </p>
      <AimlSetUp src={img} alt="Aiml set-up" />
      <p>
        Cirque du Data can be visited till December 1 at RAUM in Utrecht. The
        performances of The Big Datashow take place on October 31, November
        1,2,3. Tickets and more information:{' '}
        <Link href="https://raumutrecht.nl/event/cirque-du-data/">
          cirquedudata.nl
        </Link>
      </p>
      <h2>Colophon</h2>
      <ul>
        <li>
          <Bold>Creative Direction and Production</Bold> —{' '}
          <Link href="https://www.cleverfranke.com/">CLEVER°FRANKE</Link>:
          Thomas Clever, Gert Franke
        </li>
        <li>
          <Bold>Concept</Bold> — CLEVER°FRANKE: Gert Franke, Jonas Groot
          Kormelink
        </li>
        <li>
          <Bold>Creative Development, Design and Production</Bold> —{' '}
          <Link href="http://iboibelings.nl/">Ibo Ibelings</Link>,{' '}
          <Link href="http://www.jellereith.nl/">Jelle Reith</Link>
        </li>
        <li>
          <Bold>Front End Development</Bold> — CLEVER°FRANKE: Eva-Maria
          Strumegger, Nick Rutten
        </li>
        <li>
          <Bold>Sentiment Analysis and Data Aggregation</Bold> —{' '}
          <Link href="https://ai-applied.nl/">AI-Applied</Link>: Bruno Jakic,
          Mark Mooij
        </li>
        <li>
          <Bold>3D render</Bold> — CLEVER°FRANKE: Joost Mommers
        </li>
        <li>
          <Bold>Pen Drawing Mechanism</Bold> —{' '}
          <Link href="http://www.thomasvandenbliek.nl/">
            Thomas van den Bliek
          </Link>
        </li>
        <li>
          <Bold>Construction Design and Production</Bold> —{' '}
          <Link href="https://desmidse-metaal.nl/">De Smidse Metaal</Link>
        </li>
        <li>
          <Bold>Robotics Advise</Bold> —{' '}
          <Link href="http://en.mertisoft.nl/">Ron Mertens</Link>
        </li>
        <li>
          <Bold>Mechanical Advise</Bold> — Henry Berends
        </li>
        <li>
          <Bold>Press Contact</Bold> – For all media inquiries, please contact
          Gert Franke via{' '}
          <Link href="mailto:gert@cleverfranke.com">gert@cleverfranke.com</Link>{' '}
          or <Link href="tel:+31641010036">+31 6 41 01 00 36</Link>.
        </li>
      </ul>
      <p>
        Aiml is created in coproduction with{' '}
        <Link href="https://raumutrecht.nl/">RAUM</Link>.
      </p>
    </div>
  );
}

Information.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Information;
