import React from "react";
import PropTypes from "prop-types";
import MessageContentAiml from "./MessageContentAiml";
import MessageContentSocialMedia from "./MessageContentSocialMedia";
import { getSentimentColor } from "../helpers/helpers";

import styled from "styled-components/macro";

export const StyledMessageBox = styled.div`
  padding: 1rem;
  margin: 1.5rem 1.5rem;
  background-color: ${({ bgc }) => bgc};
`;

const MessageBoxSocialMedia = styled(StyledMessageBox)`
  border-radius: 1em;
`;

function MessageBox(props) {
  const { element } = props;
  const color = getSentimentColor(element.sentiment_score);

  if (element.type === "post")
    return (
      <MessageBoxSocialMedia bgc={color}>
        <MessageContentSocialMedia element={element} />
      </MessageBoxSocialMedia>
    );
  else if (element.type === "person")
    return (
      <StyledMessageBox bgc={color}>
        <MessageContentAiml element={element} />
      </StyledMessageBox>
    );
  else return null;
}

MessageBox.propTypes = {
  element: PropTypes.shape({ posted_at: PropTypes.number.isRequired })
    .isRequired
};

export default MessageBox;
