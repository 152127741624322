import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components/macro";
import { hideVisually } from "polished";
import logo from "./logo.svg";
import Link from "./Link";

const Logo = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
`;

const LogoImg = styled.img`
  height: 2rem;
  margin-right: 0;

  @media screen and (min-width: 750px) {
    height: 2.35rem;
    margin-right: 2rem;
  }

  @media screen and (min-width: 1500px) {
    height: 2.7rem;
  }
`;

const Tagline = styled.p`
  font-size: 1.5em;
  display: flex;
  align-items: center;
  line-height: 0;

  @media screen and (min-width: 1500px) {
    font-size: 1.8em;
  }

  @media screen and (max-width: 749px) {
    ${hideVisually()};
  }
`;

const Nav = styled.nav`
  font-size: 1em;
  width: calc(100%-2rem);
  background-color: black;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 750px) {
    padding: 0.5rem 2rem;
  }
`;

const FurtherInformation = styled.ul`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

function Topbar(props) {
  const { className, setSidebarState, sidebarState, physicalLocation } = props;

  return (
    <Nav aria-label="Primary Navigation" className={className}>
      <Logo>
        <LogoImg src={logo} alt="AIML" />
        <Tagline>in {physicalLocation}</Tagline>
      </Logo>
      <FurtherInformation>
        <Link
          setSidebarState={setSidebarState}
          sidebarState={sidebarState}
          elementIdentifier={"instruction"}
          text={"How does this work?"}
          icon={"?"}
        />
        <Link
          setSidebarState={setSidebarState}
          sidebarState={sidebarState}
          elementIdentifier={"information"}
          text={"Who am I?"}
          icon={"i"}
        />
      </FurtherInformation>
    </Nav>
  );
}

Topbar.propTypes = {
  className: PropTypes.string.isRequired,
  setSidebarState: PropTypes.func.isRequired,
  sidebarState: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    contentIdentifier: PropTypes.string,
    windowScrollY: PropTypes.number.isRequired
  }).isRequired
};

export default Topbar;
