import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components/macro";
import { timeFormat } from "d3-time-format";

const StyledMessageDate = styled.p`
  text-align: right;
  margin: 0;
  font-size: 0.8em;
`;

function MessageDate(props) {
  const { postedAt, channel } = props;
  const formatDate = timeFormat("%A %H:%M");

  return (
    <StyledMessageDate>
      {formatDate(new Date(postedAt)) + " " + channel}
    </StyledMessageDate>
  );
}

MessageDate.propTypes = {
  channel: PropTypes.string.isRequired,
  postedAt: PropTypes.number.isRequired
};

export default MessageDate;
