import React, { useState, useEffect, useLayoutEffect } from "react";
import styled, { css } from "styled-components/macro";

import "./css/App.css";
import { useFetch } from "react-fetch-hooks";

import { apiConfig } from "./Api";

import Topbar from "./components/Topbar";
import AimlView from "./components/AimlView";
import Messages from "./components/Messages";
import Sidebar from "./components/Sidebar";
import SplashScreen from "./components/SplashScreen";
import AimlSleepsScreen from "./components/AimlSleepsScreen";
import { colors, scaleColors } from "./styles/colors";
import DataVisContainer from "./components/DataVisContainer";
import { youtubeLink, physicalLocation } from "./config";

import { BarLoader } from "react-spinners";

const StyledDataVisContainer = styled(DataVisContainer)`
  display: flex;
  background-color: ${colors.darkGrey};
  flex-direction: column;

  @media screen and (min-width: 1500px) and (min-height: 900px) {
    height: 50%;
  }
`;

const StyledAimlView = styled(AimlView)`
  @media screen and (min-width: 1500px) and (min-height: 900px) {
    height: 50%;
  }
`;

const StyledMessages = styled(Messages)`
  @media screen and (min-width: 1500px) and (min-height: 900px) {
    overflow-y: scroll;
    height: calc(100vh - 70px - 3rem);
  }
  padding: 1rem 0;
`;

const StyledTopbar = styled(Topbar)`
  height: 70px;
  padding: 0.5rem 1rem;
  position: fixed;
  width: calc(100vw - 2rem);
`;

const Grid = styled.div`
  max-width: 100vw;
  flex-grow: 1;
  @media screen and (min-width: 1500px) and (min-height: 900px) {
    display: flex;
    ${({ isOpen }) =>
      isOpen &&
      css`
        width: 67vw;
      `};
  }
`;

const GridHelper = styled.div`
  @media screen and (min-width: 1500px) and (min-height: 900px) {
    width: 50%;
    display: flex;
    flex-direction: column;
  }
`;

const MessagesContainer = styled.div`
  background-color: ${colors.grey};
  @media screen and (min-width: 1500px) and (min-height: 900px) {
    width: 50%;
  }
`;

const ContentWrapper = styled.div`
  padding-top: calc(70px + 1em);
  display: flex;
  min-height: calc(100vh - 70px - 1em);
`;

const InfoMessage = styled.div`
  color: white;
  height: calc(100% - 2rem);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  text-align: center;
`;

const loaderStyles = css`
  display: block;
  margin: 0 auto;
  color: ${colors.positive};
`;

function App() {
  const [isAimlAwake, setIsAimlAwake] = useState();
  const [endOfLastSession, setEndOfLastSession] = useState();
  const [hoursBack, setHoursBack] = useState();

  const [kpiData, setKpiData] = useState({});
  const [seriesData, setSeriesData] = useState([]);
  const [seriesMessagesData, setSeriesMessagesData] = useState([]);
  const [renderAimlVideo, setRenderAimlVideo] = useState(false);
  const [hasUserConfirmedSleepMode, setHasUserConfirmedSleepMode] = useState(
    false,
    () => {
      return true;
    }
  );
  const [sidebarState, setSidebarState] = useState({
    isOpen: false,
    contentIdentifier: null,
    windowScrollY: 0
  });

  const { url, kpiPath, seriesPath, refreshIntervalInSeconds } = apiConfig;

  const { error: errorKpiData, body: fetchedKpiData } = useFetch({
    url: url + kpiPath,
    refreshInterval: refreshIntervalInSeconds * 1000
  });

  const {
    error: errorSeriesData,
    body: fetchedSeries,
    isFetched: loadedSeriesData
  } = useFetch({
    url: url + seriesPath + "?metadataOnly=true",
    refreshInterval: refreshIntervalInSeconds * 1000
  });
  const {
    error: errorSeriesMessagesData,
    body: fetchedSeriesMessages,
    isFetched: loadedSeriesMessagesData
  } = useFetch({
    url: url + seriesPath + "?limit=20",
    refreshInterval: refreshIntervalInSeconds * 1000
  });

  useEffect(() => {
    if (fetchedKpiData && fetchedSeries) {
      if (fetchedSeries.data.length === 0) {
        setKpiData({ sentiment_trend: 0, message_count: 0 });
        setSeriesData([]);
      } else {
        setKpiData({
          ...fetchedKpiData,
          message_count: fetchedSeries.data.length
        });
        setSeriesData(fetchedSeries.data);
        setIsAimlAwake(fetchedSeries.config.isAimlAwake);
        setEndOfLastSession(fetchedSeries.config.endOfLastSession);
        setHoursBack(fetchedSeries.config.hoursBack);
      }
    }
  }, [fetchedKpiData, fetchedSeries]);

  useEffect(() => {
    if (fetchedSeriesMessages) {
      setSeriesMessagesData(fetchedSeriesMessages.data);
    }
  }, [fetchedSeriesMessages]);

  useLayoutEffect(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth > 1000) setRenderAimlVideo(true);
  }, []);

  const error = errorSeriesData || errorKpiData || errorSeriesMessagesData;

  if (!loadedSeriesData) {
    return (
      <SplashScreen>
        <BarLoader
          css={loaderStyles}
          sizeUnit={"px"}
          size={150}
          color={colors.positive}
        />
      </SplashScreen>
    );
  }

  if (!isAimlAwake && !hasUserConfirmedSleepMode) {
    return (
      <AimlSleepsScreen
        endOfLastSession={endOfLastSession}
        physicalLocation={physicalLocation}
        setHasUserConfirmedSleepMode={setHasUserConfirmedSleepMode}
      />
    );
  }

  return (
    <>
      <StyledTopbar
        physicalLocation={physicalLocation}
        setSidebarState={setSidebarState}
        sidebarState={sidebarState}
      />
      <ContentWrapper id="content">
        <Grid isOpen={sidebarState.isOpen}>
          <GridHelper>
            <>
              <StyledDataVisContainer
                hoursBack={hoursBack}
                physicalLocation={physicalLocation}
                endOfLastSession={endOfLastSession}
                isAimlAwake={isAimlAwake}
                kpiData={kpiData}
                seriesData={seriesData}
                scaleColors={scaleColors}
              />

              <StyledAimlView
                youtubeLink={youtubeLink}
                renderAimlVideo={renderAimlVideo}
                setRenderAimlVideo={setRenderAimlVideo}
              />
            </>
          </GridHelper>
          <MessagesContainer>
            {error ? (
              <InfoMessage>
                Error - AIML had some problems loading the data.
              </InfoMessage>
            ) : !loadedSeriesMessagesData ? (
              <InfoMessage>
                <p>AIML is analyzing the latest messages for you.</p>
                <BarLoader
                  css={loaderStyles}
                  sizeUnit={"px"}
                  size={150}
                  color={colors.positive}
                />
              </InfoMessage>
            ) : (
              <StyledMessages data={seriesMessagesData} />
            )}
          </MessagesContainer>
        </Grid>
        <Sidebar
          sidebarState={sidebarState}
          setSidebarState={setSidebarState}
        />
      </ContentWrapper>
    </>
  );
}

export default App;
