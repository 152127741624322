import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components/macro";
import MessageDate from "./MessageDate";
import { format } from "d3-format";
import { HighlightText } from "../styles/styleHelpers";
import { getMainSentiment } from "../helpers/helpers";

const PersonImage = styled.img`
  height: 8em;
`;

const PercentScore = styled.div`
  padding: 0 1rem;
`;

const AimlReport = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 600px) {
    flex-direction: row;
    align-items: center;
  }
`;

const MainSentimentReport = styled.p`
  @media screen and (min-width: 600px) {
    padding-left: 1rem;
  }
  flex-grow: 1;
`;

const ImagePercentReport = styled.div`
  display: flex;
  align-items: center;
  @media screen and (min-width: 400px) {
    min-width: 18em;
  }
`;

// https://stackoverflow.com/questions/58082058/weird-image-with-svg-in-src-attribute-in-edge-browser
function fixSVGDiagram(svgString) {
  svgString = svgString.replace("<![CDATA[", "").replace("]]>", ""); // If styles occured, Edge crashes on that
  svgString = svgString.replace(/#/g, "temporaryhash"); // Because of hasthag issues (in styles)
  svgString = encodeURI(svgString); // Magic  happens
  svgString = svgString.replace(/temporaryhash/g, "%23"); // Get back hashtag

  return "data:image/svg+xml;charset=utf-8," + svgString;
}

function MessageBoxAiml(props) {
  const { element } = props;

  const channel = "at Aiml";
  const sentiments = element.sentiment_values;
  const mainSentiment = getMainSentiment(sentiments);

  return (
    <>
      <MessageDate postedAt={element.posted_at} channel={channel} />
      <AimlReport>
        <ImagePercentReport>
          {element.image && (
            <PersonImage src={fixSVGDiagram(element.image)} alt="person" />
          )}
          <PercentScore>
            {Object.keys(sentiments).map(element => {
              return (
                <p key={element}>
                  {element + ": " + format(".0%")(sentiments[element])}
                </p>
              );
            })}
          </PercentScore>
        </ImagePercentReport>
        <MainSentimentReport>
          AIML thought this person was{" "}
          <HighlightText>{mainSentiment}</HighlightText>.
        </MainSentimentReport>
      </AimlReport>
    </>
  );
}

MessageBoxAiml.propTypes = {
  color: PropTypes.string.isRequired,
  element: PropTypes.shape({ posted_at: PropTypes.number.isRequired })
    .isRequired
};

export default MessageBoxAiml;
