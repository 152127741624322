import { colors } from "../styles/colors";
import { timeFormat } from "d3-time-format";

export const formatTime = timeFormat("%A, %d %b. %Y");

/**
 * Evaluates which sentiment has the highest percent score.
 *
 * @export
 * @param {*} sentiments - Object of sentiments (key) and according percent score (value).
 * @returns - The sentiment (as string) with the highest percent score.
 */
export function getMainSentiment(sentiments) {
  let max = {
    key: null,
    val: null
  };

  Object.keys(sentiments).forEach(key => {
    if (max.val === null || sentiments[key] > max.val) {
      max.key = key;
      max.val = sentiments[key];
    }
  });

  return max.key;
}

/**
 * Generates random sentiment values.
 *
 * @export
 * @returns - Object with random sentiment values - sentiments (key) and according percent score (value).
 */
export function getRandomSentimentValues() {
  const disgusted = getRandomInt(101);
  const confused = getRandomInt(101 - disgusted);
  const surprised = getRandomInt(101 - disgusted - confused);
  const happy = 100 - disgusted - confused - surprised;

  return {
    disgusted: disgusted / 100,
    confused: confused / 100,
    surprised: surprised / 100,
    happy: happy / 100
  };
}

/**
 * Calculates the slots for the elements of the message graph.
 *
 * @export
 * @param {array} [data=[]] - Data filtered by sentiment score.
 * @param {*} xScale - ScaleTime to calculate the position of an element.
 * @param {*} elementInfo - Object that specifies the size, stroke and padding of the elements.
 * @param {*} height - Height to calculate the number of possible rows.
 * @returns - An array containing an array of elements per row.
 */
export function calcSlots(data = [], xScale, elementInfo, height) {
  if (data.length === 0) return [];

  const { size, stroke, padding } = elementInfo;

  const maxDepth = Math.round(height / (size + padding + stroke) - 0.5);

  const slots = [];

  data.forEach(element => {
    const positionCurrentElement = xScale(new Date(element.posted_at));

    for (let i = 0; i < maxDepth; i++) {
      const currentRow = slots[i] || [];

      // create a new array if the row is empty
      if (currentRow.length === 0) {
        currentRow.push(element);
        slots[i] = currentRow;
        break;
      }

      const lastElement = currentRow[currentRow.length - 1];
      const positionLastElement = xScale(new Date(lastElement.posted_at));
      const distance = positionCurrentElement - positionLastElement;
      const minSpaceBetween = size + stroke * 2;

      // true if the maxDepth is reached
      // or current element does not overlap with the last element
      if (i + 1 === maxDepth || distance > minSpaceBetween) {
        currentRow.push(element);
        break;
      }
    }
  });

  return slots;
}

/**
 * Filters data by sentiment score.
 *
 * @export
 * @param {*} data - Data array containing all post objects.
 * @returns - Object containing an array of objects for each sentiment score (-1, 0, 1).
 */
export function filterBySentimentScore(data) {
  let positiveSentimentScore = [];
  let neutralSentimentScore = [];
  let negativeSentimentScore = [];
  data.forEach(element => {
    if (element.sentiment_score === 1) positiveSentimentScore.push(element);
    else if (element.sentiment_score === -1)
      negativeSentimentScore.push(element);
    else if (element.sentiment_score === 0) neutralSentimentScore.push(element);
  });

  return {
    positiveSentimentScore,
    neutralSentimentScore,
    negativeSentimentScore
  };
}

/**
 * Generates a random positive integer between 0 and an given maximum boundary.
 *
 * @export
 * @param {*} max - Positive integer - maximum upper boundary (boundary it self is excluded).
 * @returns - Random integer between 0 and max (max excluded).
 */
export function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

/**
 * Sets the sidebar state to open.
 *
 * @export
 * @param {*} setSidebarState - Function to set the sidebar sate.
 * @param {*} elementIdentifier - String to identify clicked element.
 */
export function openSidebar(setSidebarState, elementIdentifier) {
  const windowScrollY = window.scrollY;
  setSidebarState({
    isOpen: true,
    contentIdentifier: elementIdentifier,
    windowScrollY: windowScrollY
  });
  document.getElementById("root").classList.add("no-scroll");
  document.getElementById("root").scrollTo(0, windowScrollY);
}

/**
 * Sets the sidebar state to closed.
 *
 * @export
 * @param {*} sidebarState - Current sidebar state.
 * @param {*} setSidebarState - Function to set the sidebar sate.
 */
export function closeSidebar(sidebarState, setSidebarState) {
  const storedWindowScrollY = sidebarState.windowScrollY;
  setSidebarState({
    isOpen: false,
    contentIdentifier: null,
    windowScrollY: 0
  });
  document.getElementById("root").classList.remove("no-scroll");
  window.scrollTo(0, storedWindowScrollY);
}

/**
 * Returns a color depending on the passed sentiment.
 *
 * @export
 * @param {*} sidebarState - Current sidebar state.
 * @param {*} setSidebarState - Function to set the sidebar sate.
 */
export function getSentimentColor(sentiment) {
  let color;
  if (sentiment === 1) color = colors.positive;
  else if (sentiment === -1) color = colors.negative;
  else color = "white";
  return color;
}
