import React from "react";
import PropTypes from "prop-types";

import MessageDate from "./MessageDate";

function MessageBoxSocialMedia(props) {
  const { element } = props;

  return (
    <>
      <MessageDate
        postedAt={element.posted_at}
        channel={"on " + element.channel}
      />
      <p>{unescape(element.post_content)}</p>
    </>
  );
}

MessageBoxSocialMedia.propTypes = {
  color: PropTypes.string.isRequired,
  element: PropTypes.shape({
    posted_at: PropTypes.number.isRequired,
    sentiment_score: PropTypes.number.isRequired
  }).isRequired
};

export default MessageBoxSocialMedia;
