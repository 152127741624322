import React from "react";
import PropTypes from "prop-types";

import Instruction from "./Instruction";
import Information from "./Information";

function SidebarContent(props) {
  const { className, sidebarState } = props;

  if (sidebarState.contentIdentifier === "instruction")
    return <Instruction className={className} />;
  else return <Information className={className} />;
}

SidebarContent.propTypes = {
  className: PropTypes.string.isRequired,
  sidebarState: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    contentIdentifier: PropTypes.string,
    windowScrollY: PropTypes.number.isRequired
  }).isRequired
};

export default SidebarContent;
