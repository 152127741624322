import React from "react";
import PropTypes from "prop-types";

import { timeFormat } from "d3-time-format";
import styled from "styled-components/macro";

const StyledText = styled.text`
  fill: white;
  stroke: none;
`;

function TickText(props) {
  const { tick, xScale, height } = props;

  const formatDate = timeFormat("%H");

  return (
    <StyledText
      textAnchor="middle"
      key={tick + "_text"}
      fill="white"
      x={xScale(tick)}
      y={height}
    >
      {formatDate(tick) + ":00"}
    </StyledText>
  );
}

TickText.propTypes = {
  height: PropTypes.number.isRequired,
  tick: PropTypes.instanceOf(Date).isRequired,
  xScale: PropTypes.func.isRequired
};

export default TickText;
