import React from "react";
import PropTypes from "prop-types";

import { scaleBand, scaleLinear } from "d3-scale";
import styled from "styled-components/macro";
import { hideVisually } from "polished";

const Text = styled.text`
  @media screen and (max-width: 500px) {
    ${hideVisually()};
  }
`;

function GraphPercent(props) {
  const { height, margin, scaleColors, sentimentTrend = 0 } = props;
  const width = props.width - margin.left - margin.right;

  const bandScale = scaleBand()
    .domain(scaleColors)
    .range([0, width]);

  const linearScale = scaleLinear()
    .domain([-0.5, 0.5])
    .range([0, width]);

  const trianglePositon = linearScale(sentimentTrend);

  const triangleSize = 17;
  const triangleMarginBottom = 8;

  const trianglePoints = [
    margin.left - triangleSize / 2 + trianglePositon,
    margin.top - triangleSize - triangleMarginBottom,
    margin.left + triangleSize / 2 + trianglePositon,
    margin.top - triangleSize - triangleMarginBottom,
    margin.left + trianglePositon,
    margin.top - triangleMarginBottom
  ];

  return (
    <svg
      width={width + margin.left + margin.right}
      height={height + margin.top + margin.bottom}
    >
      <polygon
        points={trianglePoints}
        fill="none"
        stroke="white"
        strokeWidth="1"
      />
      {scaleColors.map(color => (
        <rect
          key={color}
          y={margin.top}
          x={bandScale(color) + margin.left}
          fill={color}
          width={bandScale.bandwidth()}
          height={height}
        />
      ))}
      <g fill="white">
        <g textAnchor="start">
          <text x={margin.left} y={margin.top + height + 20}>
            -50%
          </text>
          <Text x={margin.left} y={margin.top + height + 40}>
            very negative
          </Text>
        </g>
        <g textAnchor="middle">
          <text x={width / 2 + margin.left} y={margin.top + height + 20}>
            0
          </text>
          <Text x={width / 2 + margin.left} y={margin.top + height + 40}>
            neutral
          </Text>
        </g>
        <g textAnchor="end">
          <text x={width + margin.left} y={margin.top + height + 20}>
            +50%
          </text>
          <Text x={width + margin.left} y={margin.top + height + 40}>
            very positive
          </Text>
        </g>
      </g>
    </svg>
  );
}

GraphPercent.propTypes = {
  height: PropTypes.number.isRequired,
  margin: PropTypes.shape({
    top: PropTypes.number.isRequired,
    bottom: PropTypes.number.isRequired,
    right: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired
  }).isRequired,
  scaleColors: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  sentimentTrend: PropTypes.number,
  width: PropTypes.number.isRequired
};

export default GraphPercent;
