import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components/macro";
import { hideVisually } from "polished";
import LinkIcon from "./LinkIcon";
import { closeSidebar, openSidebar } from "../helpers/helpers";

const StyledLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0.5rem;
  font-size: 1.3em;
  color: white;
  text-decoration: none;
  line-height: 0;

  p {
    margin-left: 2.5rem;
    @media screen and (max-width: 1250px) {
      ${hideVisually()};
    }
  }
`;

function Link(props) {
  const {
    setSidebarState,
    sidebarState,
    elementIdentifier,
    text,
    icon
  } = props;

  const toggelSidebar = (event, elementIdentifier) => {
    event.preventDefault();

    if (!sidebarState.isOpen) openSidebar(setSidebarState, elementIdentifier);
    else {
      if (sidebarState.contentIdentifier === elementIdentifier)
        closeSidebar(sidebarState, setSidebarState);
      else
        setSidebarState({
          ...sidebarState,
          contentIdentifier: elementIdentifier
        });
    }
  };

  return (
    <StyledLink
      href="#"
      onClick={event => toggelSidebar(event, elementIdentifier)}
    >
      <p>{text}</p>
      <LinkIcon
        icon={icon}
        sidebarState={sidebarState}
        elementIdentifier={elementIdentifier}
      />
    </StyledLink>
  );
}

Link.propTypes = {
  elementIdentifier: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  setSidebarState: PropTypes.func,
  sidebarState: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    contentIdentifier: PropTypes.string,
    windowScrollY: PropTypes.number.isRequired
  }).isRequired,
  text: PropTypes.string.isRequired
};

export default Link;
