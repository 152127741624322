import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import { colors } from "../styles/colors";

import { useResizeObserver } from "react-resize-observer-hook";
import styled from "styled-components/macro";

import KPIs from "./KPIs";
import GraphPercent from "./GraphPercent";
import GraphMessages from "./GraphMessages";

import { formatTime } from "../helpers/helpers";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
`;

const SleepingMessage = styled.div`
  background-color: ${colors.negative};
  padding: 1em;
  text-align: center;
`;

function DataVisContainer(props) {
  const {
    className,
    hoursBack,
    kpiData,
    seriesData,
    scaleColors,
    endOfLastSession,
    isAimlAwake,
    physicalLocation
  } = props;

  const [width, setWidth] = useState(0);
  const parentRef = useRef();

  useResizeObserver(parentRef, entry => {
    setWidth(entry.width);
  });

  return (
    <div className={className}>
      {!isAimlAwake && (
        <SleepingMessage>
          AIML is currently sleeping.{" "}
          <span role="img" aria-label="sleeping icon">
            💤
          </span>{" "}
          This is her last session from {formatTime(new Date(endOfLastSession))}{" "}
          in {physicalLocation}.
        </SleepingMessage>
      )}
      <Wrapper ref={parentRef}>
        <KPIs data={kpiData} scaleColors={scaleColors} hoursBack={hoursBack} />
        <GraphPercent
          width={width}
          height={10}
          margin={{ top: 40, right: 35, bottom: 60, left: 35 }}
          scaleColors={scaleColors}
          sentimentTrend={kpiData.sentiment_trend}
        />
        <GraphMessages
          isAimlAwake={isAimlAwake}
          endOfLastSession={endOfLastSession}
          width={width}
          height={110}
          margin={{ top: 10, right: 35, bottom: 10, left: 35 }}
          scaleColors={scaleColors}
          // reverse messages so the stacking works correctly
          data={seriesData.slice(0).reverse()}
        />
      </Wrapper>
    </div>
  );
}

DataVisContainer.propTypes = {
  kpiData: PropTypes.shape({
    sentiment_trend: PropTypes.number,
    message_count: PropTypes.number
  }),
  scaleColors: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  seriesData: PropTypes.arrayOf(
    PropTypes.shape({
      posted_at: PropTypes.number.isRequired,
      sentiment_score: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired
    })
  )
};

export default DataVisContainer;
