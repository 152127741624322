import React from "react";
import PropTypes from "prop-types";

function Tick(props) {
  let { tick, xScale, height, small } = props;
  const y1 = small ? height / 4 : 0;
  const y2 = small ? (height / 4) * 2.5 : height;

  return (
    <line
      key={tick + "_line"}
      stroke="white"
      strokeWidth="1"
      strokeDasharray="2 2"
      x1={xScale(tick)}
      x2={xScale(tick)}
      y1={y1}
      y2={y2}
    />
  );
}

Tick.propTypes = {
  height: PropTypes.number.isRequired,
  tick: PropTypes.instanceOf(Date).isRequired,
  xScale: PropTypes.func.isRequired
};

export default Tick;
