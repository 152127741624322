import React from "react";
import styled from "styled-components/macro";
import SplashScreen from "./SplashScreen";
import { colors } from "../styles/colors";
import logo from "./logo.svg";
import { formatTime } from "../helpers/helpers";

const LogoImg = styled.img`
  height: 2rem;
  margin-bottom: 1em;
`;

const SplashScreenButton = styled.button`
  background-color: ${colors.positive};
  border: none;
  padding: 0.6rem;
  border-radius: 1rem;
  margin-top: 1rem;
  text-transform: uppercase;
  cursor: pointer;
`;

function AimlSleepsScreen(props) {
  const {
    endOfLastSession,
    physicalLocation,
    setHasUserConfirmedSleepMode
  } = props;

  return (
    <SplashScreen>
      <LogoImg src={logo} alt="AIML" />
      <p>
        is currently sleeping. <span aria-hidden="true">💤</span>
      </p>
      <p>
        But you can view her last session from{" "}
        {formatTime(new Date(endOfLastSession))} in {physicalLocation}.
      </p>
      <SplashScreenButton onClick={setHasUserConfirmedSleepMode}>
        Check out last session
      </SplashScreenButton>
    </SplashScreen>
  );
}

export default AimlSleepsScreen;
