import React from "react";
import PropTypes from "prop-types";

function ChartElement(props) {
  const { position, element, elementInfo, color = "white" } = props;

  // round the x/y coordinates to whole pixel values
  // increases DOM performance and reduces the change for rendering artifacts
  position.x = Math.round(position.x);
  position.y = Math.round(position.y);

  if (element.type === "post") {
    return (
      <circle
        cx={position.x}
        cy={position.y}
        key={element.posted_at}
        r={elementInfo.size / 2}
        fill="none"
        stroke={color}
        strokeWidth={elementInfo.stroke}
      />
    );
  } else if (element.type === "person") {
    const positionRect = {
      x: position.x - elementInfo.size / 2,
      y: position.y - elementInfo.size / 2
    };

    return (
      <rect
        transform={`translate(${positionRect.x} ${positionRect.y}),
              rotate(45,${elementInfo.size / 2},${elementInfo.size / 2})`}
        key={element.posted_at}
        height={elementInfo.size}
        width={elementInfo.size}
        fill={color}
        stroke="none"
        strokeWidth="2"
      />
    );
  } else return null;
}

ChartElement.propTypes = {
  color: PropTypes.string,
  element: PropTypes.shape({
    posted_at: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired,
  elementInfo: PropTypes.shape({
    size: PropTypes.number.isRequired,
    stroke: PropTypes.number.isRequired
  }).isRequired,
  position: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired
  }).isRequired
};

export default ChartElement;
