import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components/macro";
import { colors } from "../styles/colors";

const StyledSvg = styled.svg`
  margin-right: 0.4rem;
`;

const Arrow = styled.path`
  stroke: black;
  fill: none;
  stroke-width: 1;
  @media screen and (min-width: 1500px) and (min-height: 900px) {
    stroke: white;
  }
`;

const ButtonWrapper = styled.div`
  display: block;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 1500px) and (min-height: 900px) {
    display: inline;
    background-color: ${colors.darkGrey};
  }
`;

const AimlViewButtonStyled = styled.button`
  background-color: ${colors.lightGrey};
  border: none;
  border-radius: 0.3em;
  margin: 3rem;
  color: black;
  display: flex;
  align-items: center;
  padding: 0.3rem;
  cursor: pointer;
  @media screen and (min-width: 1500px) and (min-height: 900px) {
    background-color: ${colors.darkGrey};
    color: ${colors.lightGrey};
    position: absolute;
    margin: 1rem;
  }
`;

function AimlViewButton(props) {
  const { renderAimlVideo, setRenderAimlVideo } = props;
  return (
    <ButtonWrapper>
      <AimlViewButtonStyled
        onClick={() =>
          setRenderAimlVideo((renderAimlVideo) => !renderAimlVideo)
        }
      >
        <StyledSvg height="20" width="20">
          <circle
            cx="10"
            cy="10"
            r="8"
            stroke={colors.lightGrey}
            strokeWidth="1"
          />
          <circle
            cx="10"
            cy="10"
            r="4"
            stroke={colors.lightGrey}
            strokeWidth="1"
          />{" "}
        </StyledSvg>
        <span>Aiml view</span>
        {!renderAimlVideo && (
          <StyledSvg height="20" width="30">
            <Arrow d="M10 3 L18 15 L26 3" />
          </StyledSvg>
        )}
      </AimlViewButtonStyled>
    </ButtonWrapper>
  );
}

AimlViewButton.propTypes = {
  renderAimlVideo: PropTypes.bool.isRequired,
  setRenderAimlVideo: PropTypes.func.isRequired,
};

export default AimlViewButton;
